import { Dispatch, FC, SetStateAction, useState } from 'react'

import {
  FindUserCourseDataQuery,
  FindUserCourseDataQueryVariables,
  LearnerCourse,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import LearnerSummary from 'src/components/Learner/Courses/UserCoursesSummaryCell/LearnerSummary/LearnerSummary'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindUserCourseDataQuery(
    $membershipIds: [Int!]
    $reportingFlags: ReportingFlags!
    $id: Int!
  ) {
    learnerCourse(id: $id) {
      id
      name
      heroUrl
      learnerCategoryId
      learnerActivities(status: PUBLISHED) {
        id
        name
        learnerTasks(status: PUBLISHED) {
          id
          name
        }
      }
      reporting(
        reportingFlags: $reportingFlags
        membershipIds: $membershipIds
      ) {
        learnerCourseId
        attempts {
          status
          percentage
          relatedTracking {
            ... on LearnerCourseTracking {
              id
              membershipId
              createdAt
              updatedAt
              status
            }
          }
          tasks {
            learnerTaskId
            type
            name
            attempt {
              status
              percentage
              relatedTracking {
                ... on LearnerTaskTracking {
                  id
                  membershipId
                }
              }
            }
          }
        }
      }
    }
    membershipGroups: membershipGroupsByClient {
      id
      name
    }
  }
`

export const Loading = () => (
  <div className="pt-8">
    <LoadingSpinner />
  </div>
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const afterQuery = ({ learnerCategories, ...rest }) => {
  return { learnerCategories, ...rest }
}
type Props = CellSuccessProps<
  FindUserCourseDataQuery,
  FindUserCourseDataQueryVariables
> & {
  learnerSummaryModalOpen: boolean
  setLearnerSummaryModalOpen: Dispatch<SetStateAction<boolean>>
  id: number
  clientId: number
  membershipIds: number
  reportingFlags: {
    includeCourseStats: boolean
    includeCourseAttempts: boolean
    includeTaskStats: boolean
    includeTaskType: boolean
    lastAttempt: boolean
    includeArchived: boolean
  }
}
export const Success: FC<Props> = ({
  learnerCourse,
  learnerSummaryModalOpen,
  setLearnerSummaryModalOpen,
  queryResult: { refetch },
}) => {
  const [selectedAttempt, setSelectedAttempt] = useState<number>(0)

  return (
    <>
      <LearnerSummary
        course={learnerCourse}
        courseMembershipGroupIds={[]}
        allMembershipGroups={[]}
        showSummary={learnerSummaryModalOpen}
        setShowSummary={setLearnerSummaryModalOpen}
        selectedAttempt={selectedAttempt}
        setSelectedAttempt={setSelectedAttempt}
        refetchLearnerCategories={refetch}
      />
    </>
  )
}
