import CourseSummary from 'src/components/Learner/Courses/CourseSummary/CourseSummary'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindLearnerCourseSummaryQuery(
    $id: Int!
    $reportingFlags: ReportingFlags!
  ) {
    learnerCategories: learnerCategoriesByClientAsAdmin(status: PUBLISHED) {
      id
      name
      isGlobal
      aclPrincipals {
        principalType
        principal {
          __typename
          ... on ACLPrincipalMembership {
            membership {
              id
              user {
                id
                name
                avatarUrl
              }
            }
          }
          ... on ACLPrincipalMembershipGroup {
            membershipGroup {
              id
              name
            }
          }
        }
      }
      learnerCourses(status: PUBLISHED, isAdmin: true) {
        id
        name
      }
    }
    currentCourse: learnerCourse(id: $id) {
      id
      name
      status
      heroStorageObject {
        id
        downloadUrl
      }
      createdBy
      learnerActivities(status: PUBLISHED) {
        id
        name
        learnerTasks(status: PUBLISHED) {
          id
          name
          rank
          text
          video
          quiz {
            id
          }
        }
      }
      reporting(reportingFlags: $reportingFlags) {
        learnerCourseId
        attempts {
          status
          percentage
          relatedTracking {
            ... on LearnerCourseTracking {
              id
              createdAt
              updatedAt
              membershipId
            }
          }
          tasks {
            learnerTaskId
            type
            name
            attempt {
              status
              percentage
              relatedTracking {
                ... on LearnerTaskTracking {
                  id
                  updatedAt
                  membershipId
                  lessonLength
                }
              }
            }
          }
        }
        stats {
          learningItems
          passingPercentage
          averageLearningTime
          averageGrade
          assignedUsers
          completionRate
          statusStates {
            COMPLETED
            IN_PROGRESS
            NOT_STARTED
          }
        }
      }
    }
    memberships: membershipsByClient(activeOnly: true) {
      id
      user {
        name
        avatarUrl
      }
      membershipGroups {
        membershipGroup {
          id
          name
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = ({ learnerCategories, currentCourse, memberships }) => {
  return (
    <CourseSummary
      learnerCategories={learnerCategories}
      currentCourse={currentCourse}
      memberships={memberships}
    />
  )
}

export const Failure = () => (
  <CourseSummary learnerCategories={[]} currentCourse={null} memberships={[]} />
)

export const Success = ({ learnerCategories, currentCourse, memberships }) => {
  return (
    <CourseSummary
      learnerCategories={learnerCategories}
      currentCourse={currentCourse}
      memberships={memberships}
    />
  )
}
