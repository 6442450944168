import { FC } from 'react'

import {
  AcademicCapIcon,
  CheckCircleIcon,
  PencilIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { LearnerCourse } from 'types/graphql'

import { toFirstDecimal } from 'src/lib/helpers'

import SummaryGridItem from './SummaryGridItem'

interface SummaryGridProps {
  stats: LearnerCourse['reporting']['stats']
}

const SummaryGrid: FC<SummaryGridProps> = ({ stats }) => {
  const iconProps = {
    className: 'text-gray-300 h-12 w-12',
  }

  const { learningItems, statusStates, assignedUsers } = stats

  const totalMemberships =
    statusStates.COMPLETED + statusStates.IN_PROGRESS + statusStates.NOT_STARTED

  const participatedRate =
    ((statusStates.IN_PROGRESS / totalMemberships) * 100).toFixed(1) ||
    0 + ((statusStates.NOT_STARTED / totalMemberships) * 100).toFixed(1) ||
    0

  const completedRate =
    ((statusStates.COMPLETED / totalMemberships) * 100).toFixed(1) || 0

  return (
    <div className="grid gap-4 h-full grid-cols-2">
      <SummaryGridItem
        title="Total Lessons"
        value={toFirstDecimal(learningItems || 0)}
        icon={<AcademicCapIcon {...iconProps} />}
        tooltipTitle="Total number of lessons."
      />
      <SummaryGridItem
        title="Participated"
        value={`${toFirstDecimal(participatedRate || 0)}%`}
        icon={<PencilIcon {...iconProps} />}
        tooltipTitle="Percentage of learners who have participated in this course."
      />
      {/* {averageTime !== undefined && (//TODO: add this back later
        <LearnerCourseSummaryGridItem
          title="AVG. Learning Time"
          value={toFirstDecimal(averageTime)}
          icon={<ClockIcon {...iconProps} />}
        />
      )} */}
      {/* {averageGrade !== undefined && (
        <LearnerCourseSummaryGridItem
          title="AVG. Grade"
          value={toFirstDecimal(averageGrade)}
          icon={<StarIcon {...iconProps} />}
        />
      )} */}
      <SummaryGridItem
        title="Assigned Users"
        value={toFirstDecimal(assignedUsers?.length || 0)}
        icon={<UsersIcon {...iconProps} />}
        tooltipTitle="The number of users who have been assigned to this course."
      />
      <SummaryGridItem
        title="Completed"
        value={`${toFirstDecimal(completedRate || 0)}%`}
        icon={<CheckCircleIcon {...iconProps} />}
        tooltipTitle="Percentage of learners who have completed this course."
      />
    </div>
  )
}

export default SummaryGrid
