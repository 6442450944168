import { FC } from 'react'

import { Avatar, Link } from '@mui/material'
import dayjs from 'dayjs'
import { LearnerAttempt, Membership } from 'types/graphql'

import CircularProgressWithLabel from 'src/components/CircularProgressWithLabel/CircularProgressWithLabel'

interface ProgressRowProps {
  learnerCourseTracking?: LearnerAttempt
  membership: Membership
  setLearnerSummaryModalOpen: (open: boolean) => void
  setSelectedUserId: (userId: number) => void
}

const ProgressRow: FC<ProgressRowProps> = ({
  learnerCourseTracking = null,
  membership,
  setLearnerSummaryModalOpen,
  setSelectedUserId,
}) => {
  const openModal = () => {
    setSelectedUserId(membership?.id)
    setLearnerSummaryModalOpen(true)
  }

  return (
    <>
      <Link
        onClick={openModal}
        style={{ textDecoration: 'none', cursor: 'pointer', color: 'inherit' }}
      >
        <div className="p-3 px-6 border-b border-gray-300">
          <div className="flex w-full items-center justify-between gap-2">
            <div>
              <div className="flex ">
                <Avatar
                  alt={''}
                  sx={{ width: 24, height: 24 }}
                  src={membership?.user?.avatarUrl}
                  className="bg-gray-300 text-gray-600 text-base"
                />
                <p className="pl-2">{membership?.user?.name}</p>
              </div>
              <p className="text-gray-400 font-light pl-8">
                {dayjs(
                  learnerCourseTracking?.relatedTracking?.updatedAt,
                ).format('DD/MM/YYYY')}
              </p>
            </div>
            <CircularProgressWithLabel
              sx={{
                color:
                  learnerCourseTracking?.status === 'COMPLETED'
                    ? '#34d399'
                    : '#3B82F6',
              }}
              className="text-indigo-600"
              value={learnerCourseTracking?.percentage || 0}
              size={'48px'}
            />
          </div>
        </div>
      </Link>
    </>
  )
}

export default ProgressRow
