import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import CourseSummaryCell from 'src/components/Learner/Courses/CourseSummary/CourseSummaryCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

const LearnerCourseSummaryPage = ({ id }) => {
  return (
    <>
      <Metadata
        title="Learner Course Summary"
        description="Learner Course Summary page"
      />
      <FeatureToggle
        feature={FLAG_FEATURES.COURSE_REPORTING}
        InactiveComponent={<FeatureDenied />}
      >
        <PageHeader title="Learner Summary" backNavigation />
        <CourseSummaryCell
          id={id}
          reportingFlags={{
            includeCourseStats: true,
            includeCourseAttempts: true,
            includeTaskStats: true,
            includeTaskType: true,
            lastAttempt: true,
            includeArchived: false,
          }}
        />
      </FeatureToggle>
    </>
  )
}

export default LearnerCourseSummaryPage
